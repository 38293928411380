import React from 'react';
import NavLink from './NavLink';
import { navLinks } from './navLinks';
import * as st from './Nav.Styled';

const Nav = () => (
  <st.Nav style={{ animation: 'rolldown 2.4s' }}>
    {navLinks.map(({ navLinkId, scrollToId }, i) => (
      <NavLink key={i} navLinkId={navLinkId} scrollToId={scrollToId} />
    ))}
  </st.Nav>
);

export default Nav;
