import styled from 'styled-components';
import { SectionTitle } from '../Services/ServicesCard.Styled';

export const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  min-height: 100vh;
  @media (max-width: 1000px) {
    flex-direction: column;
    justify-content: center;
    padding-top: 20px;
  }
`;

export const Title = styled(SectionTitle)`
  @media (max-width: 500px) {
    font-size: 30px;
    letter-spacing: 9px;
    padding: 10px;
    line-height: 4.5rem;
    min-width: 350px;
    margin: auto;
  }
`;

export const SubTitle = styled(SectionTitle)`
  color: var(--light-text);
  font-size: 34px;
  letter-spacing: 0.65rem;
  line-height: 60px;
  max-width: 825px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;

  @media (max-width: 500px) {
    font-size: 22px;
    line-height: 45px;
  }
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  min-height: 80vh;
  margin-top: 30px;
  @media (max-width: 600px) {
    //min-height: 125vh;
    width: 100%;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  h1 {
    font-family: 'conthrax-sb';
  }

  @media (max-width: 600px) {
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const CardContent = styled.div`
  display: flex;
  height: 350px;
  width: 450px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  position: relative;
  background: rgb(239, 51, 64);
  background: linear-gradient(
    274deg,
    rgba(239, 51, 64, 0.7607451201201201) 36%,
    rgba(70, 34, 37, 0.9598214285714286) 98%
  );
  padding: 40px;
  border-radius: 15px;
  color: #ef3340;
  margin-left: -24px;
  z-index: -1;
  h1 {
    font-size: 32px;
    margin-left: 20px;
    margin-top: -5px;
  }

  h3 {
    font-size: 20px;
    margin-left: 22px;
  }

  p {
    font-size: 17px;
    margin-left: 20px;
    margin-top: 30px;
    padding-right: 8px;
  }

  @media (max-width: 600px) {
    align-items: center;
    justify-content: center;
    width: 350px;
    height: 350px;
    margin-left: 0px;
    margin-top: -27px;
    margin-bottom: 30%;

    h1 {
      font-size: 28px;
      text-align: center;
      margin: auto;
    }

    h3 {
      font-size: 18px;
      margin: auto;
    }

    p {
      font-size: 16px;
      margin: auto;
      text-align: justify;
      margin-bottom: -10px;
    }
  }
`;

export const ContentOverlay = styled.div`
  background: url('https://i.ibb.co/Fmgnv0M/dwsite-bg.jpg');
  height: 323px;
  width: 436px;
  margin-top: -27px;
  margin-left: -40px;
  z-index: -1;
  border-radius: 10px;
  position: absolute;
  @media (max-width: 600px) {
    align-items: center;
    justify-content: center;
    width: 320px;
    height: 336px;
    margin-left: auto;
    margin-top: -20px;
  }
`;

export const RevCardContent = styled.div`
  display: flex;
  height: 350px;
  width: 450px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  position: relative;
  background: rgb(239, 51, 64);
  background: linear-gradient(
    120deg,
    rgba(239, 51, 64, 0.7607451201201201) 36%,
    rgba(70, 34, 37, 0.9598214285714286) 98%
  );
  padding: 40px;
  border-radius: 15px;
  color: #ef3340;
  margin-right: -24px;
  z-index: -1;

  h1 {
    font-size: 32px;
    margin-left: 20px;
    margin-top: -5px;
  }

  h3 {
    font-size: 20px;
    margin-left: 22px;
  }

  p {
    font-size: 17px;
    margin-left: 20px;
    margin-top: 30px;
    padding-right: 20px;
  }

  @media (max-width: 600px) {
    align-items: center;
    justify-content: center;
    width: 350px;
    height: 350px;
    margin-right: 0px;
    margin-top: -27px;
    margin-bottom: 30%;
    padding: 30px;

    h1 {
      font-size: 28px;
      text-align: center;
      margin: auto;
    }

    h3 {
      font-size: 18px;
      margin: auto;
    }

    p {
      font-size: 16px;
      margin: auto;
      text-align: justify;
    }
  }
`;

export const RevContentOverlay = styled.div`
  background: url('https://i.ibb.co/Fmgnv0M/dwsite-bg.jpg');
  height: 323px;
  width: 436px;
  margin-top: -27px;
  margin-left: -23px;
  z-index: -1;
  border-radius: 10px;
  position: absolute;
  @media (max-width: 600px) {
    align-items: center;
    justify-content: center;
    width: 320px;
    height: 336px;
    margin-left: auto;
    margin-top: -20px;
  }
`;

export const NathanContainer = styled.div`
  width: 350px;
  height: 350px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 25px 20px 14px 5px rgba(0, 0, 0, 0.44);
  -webkit-box-shadow: 25px 20px 14px 5px rgba(0, 0, 0, 0.44);
  -moz-box-shadow: 50px 20px 14px 5px rgba(0, 0, 0, 0.44);
  @media (max-width: 700px) {
    box-shadow: 10px 15px 14px 2px rgba(0, 0, 0, 0.44);
    -webkit-box-shadow: 10px 15px 14px 2px rgba(0, 0, 0, 0.44);
    -moz-box-shadow: 10px 15px 14px 2px rgba(0, 0, 0, 0.44);
    transition: ease 1s;
  }
`;

export const NathanImgHolder = styled.div`
  width: 100%;
  height: 100%;
  background: url('https://i.ibb.co/sH7x06M/nathan-desaturated.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 1s;
  border-radius: 15px;
  z-index: 10;
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
  }
`;

export const DougContainer = styled.div`
  width: 350px;
  height: 350px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 25px 20px 14px 5px rgba(0, 0, 0, 0.49);
  -webkit-box-shadow: 25px 20px 14px 5px rgba(0, 0, 0, 0.49);
  -moz-box-shadow: 25px 20px 14px 5px rgba(0, 0, 0, 0.49);
  @media (max-width: 700px) {
    box-shadow: 10px 15px 14px 2px rgba(0, 0, 0, 0.44);
    -webkit-box-shadow: 10px 15px 14px 2px rgba(0, 0, 0, 0.44);
    -moz-box-shadow: 10px 15px 14px 2px rgba(0, 0, 0, 0.44);
    transition: ease 1s;
  }
`;

export const DougImgHolder = styled.div`
  width: 100%;
  height: 100%;
  background: url('https://i.ibb.co/px1VbgL/doug-desaturated.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 1s;
  border-radius: 15px;
  cursor: pointer;

  &:hover {
    transform: scale(1.2);
  }
`;
