import React from 'react';
import * as SiIcons from 'react-icons/si';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';

export const front1 = {
  title: 'Web3',
  icon: <SiIcons.SiHiveBlockchain />,
};

export const front2 = {
  title: 'Front End / UX',
  icon: <FaIcons.FaUserCheck />,
};

export const front3 = {
  title: 'Back End',
  icon: <AiIcons.AiFillApi />,
};

export const back1 = {
  text: ['NFT Minting Websites', 'Smart Contracts', 'Blockchain Explorer API'],
};

export const back2 = {
  text: ['Figma Wireframes', 'Static Websites', 'React JS Web Applications'],
};

export const back3 = {
  text: ['Node JS RESTful APIs', 'MongoDB', 'PostgresQL Databases'],
};
