import React from 'react';
import { useNav } from '../../customHooks/useNav';
import * as st from './Hero.Styled';

const Hero = () => {
  const homeRef = useNav('Home');

  const handleServiceClick = (e) => {
    e.preventDefault();

    document
      .getElementById('servicesContainer')
      .scrollIntoView({ block: 'start', behavior: 'smooth' });
  };

  return (
    <st.HeroContainer ref={homeRef} id="homeContainer">
      <st.HeroContent>
        <st.Logo>
          <span style={{ animation: 'rollout 0.6s' }}>DR4</span>
          <span style={{ animation: 'rollout 1s' }}>KE+</span>
          <span style={{ animation: 'rollout 1.4s', color: 'var(--logo-red)' }}>W3</span>
          <span style={{ animation: 'rollout 1.8s' }}>ST</span>
          <h3 style={{ animation: 'rollout 2.2s', color: 'var(--logo-red)' }}>
            DEV
            <span style={{ color: 'var(--light-text)' }}>+</span>
            DESIGN
          </h3>
        </st.Logo>
        <st.ContentContainer style={{ animation: 'rollout 2.6s' }}>
          <st.HeroText>
            <h3 className="grow">
              You&apos;re here to innovate.
            </h3>
            <p className="grow">We&apos;re here to join you.</p>
          </st.HeroText>
          <st.ServicesButton onClick={handleServiceClick}>
            <h3>SERVICES</h3>
          </st.ServicesButton>
        </st.ContentContainer>
      </st.HeroContent>
    </st.HeroContainer>
  );
};
export default Hero;
