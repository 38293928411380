import styled from 'styled-components';
import { SectionTitle } from '../Services/ServicesCard.Styled';
import bg from '../../Images/grayhaze_bg.jpg';
import cardBg from '../../Images/dwsite_bg.jpg';

export const ContactContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  min-height: 50vw;
  margin: 0 auto;
  @media (max-width: 800px) {
    flex-direction: column;
  }

  a {
    text-decoration: none;
  }
`;

export const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  justify-content: center;
  background: url(${bg}) no-repeat center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  @media (max-width: 1000px) {
    flex-direction: column;
    padding: 20px;
  }
`;

export const Title = styled(SectionTitle)`
 @media (max-width: 500px) {
    font-size: 30px;
    letter-spacing: 9px;
    line-height: 4.5rem;
    margin: auto;
    margin-bottom: 20px;
    word-spacing: normal;
  }
`;

export const ServiceCard = styled.div`
  background-image: url(${cardBg});
  mix-blend-mode: screen;
  min-width: 350px;
  min-height: 350px;
  border-radius: 15px;
  margin: 2rem;
  box-shadow: 6px 20px 14px 0px rgba(0, 0, 0, 0.59);
  -webkit-box-shadow: 6px 20px 14px 0px rgba(0, 0, 0, 0.59);
  -moz-box-shadow: 6px 20px 14px 0px rgba(0, 0, 0, 0.59);
  cursor: pointer;
`;

export const ServicesButton = styled.button`
  height: 50px;
  width: 100%;
  max-width: 100px;
  font-size: 24px;
  margin: auto;
  border: 3px solid var(--logo-red);
  background: transparent;
  border-radius: 15px;
  color: var(--logo-red);
  cursor: pointer;

  &:hover {
    background: var(--logo-red);
    color: var(--light-text);
  }

  @media (max-width: 600px) {
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;

export const ServiceCardContent = styled.div`
  background: rgba(45, 41, 38, 0.7);
  border-radius: 15px;
  min-width: 350px;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ServiceCardHeader = styled.h1`
  color: var(--light-text);
  font-family: 'conthrax-sb';
  font-size: 22px;

  &:hover {
    color: var(--logo-red);
  }
`;

export const ServiceCardSubheader = styled.h3``;

export const ServiceCardText = styled.p`
  color: var(--dark-background);
  font-size: 24px;
  padding: 10px;

  &:hover {
    color: var(--logo-red);
  }
`;

export const ServiceCardIcon = styled.div`
  color: var(--dark-background);
  font-size: 60px;
  margin-top: 5%;

  &:hover {
    color: var(--logo-red);
  }
`;

export const SubTitle = styled(SectionTitle)`
  color: var(--light-text);
  font-size: 34px;
  letter-spacing: 0.5rem;
  line-height: 60px;
  max-width: 825px;
  min-width: 325px;
  font-weight: 300;
  font-family: "Poppins", sans-serif;

  @media (max-width: 500px) {
    font-size: 26px;
    line-height: 60px;
    padding-left: 7.5px;
    padding-right: 7.5px;
    letter-spacing: .25rem;
  }
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  @media (max-width: 600px) {
    min-height: 100vh;
    width: 100%;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 40px;
  height: 500px;
  width: 700px;
  justify-content: center;
  position: relative;
  background: var(--dark-background);
  border-radius: 15px;
  color: var(--dark-background);
  margin: auto;
  z-index: -1;
  box-shadow: 6px 20px 14px 0px rgba(0, 0, 0, 0.59);
  -webkit-box-shadow: 6px 20px 14px 0px rgba(0, 0, 0, 0.59);
  -moz-box-shadow: 6px 20px 14px 0px rgba(0, 0, 0, 0.59);
  @media (max-width: 700px) {
    width: 350px;
    h1 {
      font-size: 28px;
      text-align: center;
      margin: auto;
    }

    h3 {
      font-size: 18px;
      margin: auto;
    }

    p {
      font-size: 16px;
      margin: auto;
      text-align: justify;
      margin-bottom: -10px;
    }
  }
`;

export const ContentOverlay = styled.div`
  height: 100%;
  width: 100%;
  z-index: -2;
  border-radius: 10px;
  position: absolute;
  background: var(--lighter-background);
  @media (max-width: 600px) {
    align-items: center;
    justify-content: center;
  }
`;

export const DoubleOverlay = styled.div`
  height: 100%;
  width: 100%;
  z-index: -1;
  border-radius: 10px;
  position: absolute;
  background: url('https://i.ibb.co/Fmgnv0M/dwsite-bg.jpg');
  mix-blend-mode: screen;
  @media (max-width: 600px) {
    align-items: center;
    justify-content: center;
    width: 320px;
    height: 336px;
  }
`;
