import styled from 'styled-components';

export const ServiceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 60%;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const SectionTitle = styled.h1`
  width: 100%;
  font-family: 'conthrax-sb';
  font-size: 80px;
  text-align: center;
  letter-spacing: 18px;
  margin-top: 80px;
  color: var(--logo-red);

  @media (max-width: 500px) {
    font-size: 30px;
    letter-spacing: 9px;
    line-height: 4.5rem;
    margin: auto;
    margin-bottom: 20px;
    word-spacing: normal;
  }
`;

export const SubTitle = styled(SectionTitle)`
  color: var(--light-text);
  font-size: 34px;
  letter-spacing: 0.5rem;
  line-height: 60px;
  max-width: 825px;
  font-weight: 300;
  font-family: "Poppins", sans-serif;

  @media (max-width: 500px) {
    font-size: 26px;
    line-height: 60px;
  }
`;

export const SubTitle2 = styled(SectionTitle)`
  color: var(--light-text);
  font-size: 34px;
  letter-spacing: 0.65rem;
  line-height: 60px;
  max-width: 825px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;

  @media (max-width: 500px) {
    font-size: 16px;
    line-height: 60px;
    word-spacing: normal;
    letter-spacing: .325rem;
    margin-top: -20px;
  }
`;

export const ServiceCard = styled.div`
  background: radial-gradient(
      ellipse at 90px 45px,
      rgba(255, 255, 255, 1) 5%,
      rgba(71, 71, 71, 0) 100%,
      rgba(255, 255, 255, 0.5) 25%,
      rgba(255, 255, 255, 0.3) 40%,
      rgba(45, 41, 38) 100%
    ),
    linear-gradient(90deg, transparent 1%, rgb(45, 41, 38) 30%);
  background-size: 100%, 100%, 100%, 100%, 100%;
  background-repeat: no-repeat;
  min-width: 350px;
  min-height: 350px;
  border-radius: 15px;
  margin: 2rem;
  box-shadow: 6px 20px 14px 0px rgba(0, 0, 0, 0.59);
  -webkit-box-shadow: 6px 20px 14px 0px rgba(0, 0, 0, 0.59);
  -moz-box-shadow: 6px 20px 14px 0px rgba(0, 0, 0, 0.59);
`;

export const ContactCard = styled(ServiceCard)`
  :hover {
    cursor: pointer;
  }
`;

export const ServiceCardContent = styled.div`
  background: rgba(45, 41, 38, 0.7);
  border-radius: 15px;
  min-width: 350px;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ServiceCardHeader = styled.h1`
  color: var(--light-text);
  font-family: 'conthrax-sb';

  &:hover {
    color: var(--logo-red);
  }
`;

export const ServiceCardText = styled.p`
  color: var(--light-text);
  font-size: 24px;
  padding: 10px;

  &:hover {
    color: var(--logo-red);
  }
`;

export const ServiceCardIcon = styled.div`
  color: var(--light-text);
  font-size: 60px;
  margin-top: 5%;

  &:hover {
    color: var(--logo-red);
  }
`;
