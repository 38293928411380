import React, { useState } from 'react';
import { useNav } from '../../customHooks/useNav';
import * as cardData from './ServicesCardData';
import * as st from './Services.Styled';
import * as cardST from './ServicesCard.Styled';

const Services = () => {
  const servicesRef = useNav('Services');

  const [isFrontSide1, setIsFrontSide1] = useState(true);
  const [isFrontSide2, setIsFrontSide2] = useState(true);
  const [isFrontSide3, setIsFrontSide3] = useState(true);

  const renderCard1 = () => {
    const { front1 } = cardData;
    const { back1 } = cardData;

    const toggleSide1 = () => {
      if (!isFrontSide1) {
        setIsFrontSide1(true);
      } else {
        setIsFrontSide1(false);
      }
    };

    if (isFrontSide1) {
      return (
        <cardST.ServiceCard onMouseEnter={toggleSide1} onMouseLeave={toggleSide1}>
          <cardST.ServiceCardContent>
            <cardST.ServiceCardHeader>{front1.title}</cardST.ServiceCardHeader>
            <cardST.ServiceCardIcon>{front1.icon}</cardST.ServiceCardIcon>
          </cardST.ServiceCardContent>
        </cardST.ServiceCard>
      );
    }
    return (
      <cardST.ServiceCard
        style={{ animation: 'fade 2s' }}
        onMouseEnter={toggleSide1}
        onMouseLeave={toggleSide1}
      >
        <cardST.ServiceCardContent>
          <cardST.ServiceCardText>{back1.text[0]}</cardST.ServiceCardText>
          <cardST.ServiceCardText>{back1.text[1]}</cardST.ServiceCardText>
          <cardST.ServiceCardText>{back1.text[2]}</cardST.ServiceCardText>
        </cardST.ServiceCardContent>
      </cardST.ServiceCard>
    );
  };

  const renderCard2 = () => {
    const { front2 } = cardData;
    const { back2 } = cardData;

    const toggleSide2 = () => {
      if (!isFrontSide2) {
        setIsFrontSide2(true);
      } else {
        setIsFrontSide2(false);
      }
    };

    if (isFrontSide2) {
      return (
        <cardST.ServiceCard onMouseEnter={toggleSide2} onMouseLeave={toggleSide2}>
          <cardST.ServiceCardContent>
            <cardST.ServiceCardHeader>{front2.title}</cardST.ServiceCardHeader>
            <cardST.ServiceCardIcon>{front2.icon}</cardST.ServiceCardIcon>
          </cardST.ServiceCardContent>
        </cardST.ServiceCard>
      );
    }
    return (
      <cardST.ServiceCard
        style={{ animation: 'fade 2s' }}
        onMouseEnter={toggleSide2}
        onMouseLeave={toggleSide2}
      >
        <cardST.ServiceCardContent>
          <cardST.ServiceCardText>{back2.text[0]}</cardST.ServiceCardText>
          <cardST.ServiceCardText>{back2.text[1]}</cardST.ServiceCardText>
          <cardST.ServiceCardText>{back2.text[2]}</cardST.ServiceCardText>
        </cardST.ServiceCardContent>
      </cardST.ServiceCard>
    );
  };

  const renderCard3 = () => {
    const { front3 } = cardData;
    const { back3 } = cardData;

    const toggleSide3 = () => {
      if (!isFrontSide3) {
        setIsFrontSide3(true);
      } else {
        setIsFrontSide3(false);
      }
    };

    if (isFrontSide3) {
      return (
        <cardST.ServiceCard onMouseEnter={toggleSide3} onMouseLeave={toggleSide3}>
          <cardST.ServiceCardContent>
            <cardST.ServiceCardHeader>{front3.title}</cardST.ServiceCardHeader>
            <cardST.ServiceCardIcon>{front3.icon}</cardST.ServiceCardIcon>
          </cardST.ServiceCardContent>
        </cardST.ServiceCard>
      );
    }
    return (
      <cardST.ServiceCard
        style={{ animation: 'fade 2s' }}
        onMouseEnter={toggleSide3}
        onMouseLeave={toggleSide3}
      >
        <cardST.ServiceCardContent>
          <cardST.ServiceCardText>{back3.text[0]}</cardST.ServiceCardText>
          <cardST.ServiceCardText>{back3.text[1]}</cardST.ServiceCardText>
          <cardST.ServiceCardText>{back3.text[2]}</cardST.ServiceCardText>
        </cardST.ServiceCardContent>
      </cardST.ServiceCard>
    );
  };

  return (
    <st.SectionContent ref={servicesRef} id="servicesContainer">
      <cardST.SectionTitle>WHAT WE DO</cardST.SectionTitle>
      <cardST.SubTitle className="grow">All This Stuff</cardST.SubTitle>
      <cardST.SubTitle2 className="grow">
        (so you don&apos;t have to)
      </cardST.SubTitle2>
      <st.ServicesContainer>
        {renderCard1()}
        {renderCard2()}
        {renderCard3()}
      </st.ServicesContainer>
    </st.SectionContent>
  );
};

export default Services;
