import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

// eslint-disable-next-line react/prop-types
const AppContainer = ({ children }) => <Container>{children}</Container>;

export default AppContainer;
