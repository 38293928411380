import styled from 'styled-components';

export const ServicesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 75vh;
  width: 100%;
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  @media (max-width: 1500px) {
    flex-direction: column;
    width: 100%;
    padding: 20px;
  }
  @media (max-width: 500px) {
    min-height: 125vh;
  }
`;
