import React from 'react';
import * as IoIcons from 'react-icons/io';
import { useNav } from '../../customHooks/useNav';
import * as cardData from './ContactData';
import * as cst from './Contact.Styled';
import * as cardST from '../Services/ServicesCard.Styled';

const Contact = () => {
  const contactRef = useNav('Contact');

  const handleServiceClick = (e) => {
    e.preventDefault();

    document
      .getElementById('homeContainer')
      .scrollIntoView({ block: 'start', behavior: 'smooth' });
  };

  const renderCard1 = () => {
    const { front1 } = cardData;
    const { back1 } = cardData;

    return (
      <a href={`mailto: ${back1.text}`}>
        <cardST.ContactCard id="contact">
          <cardST.ServiceCardContent>
            <cst.ServiceCardHeader id="contact-header">{front1.title}</cst.ServiceCardHeader>
            <cardST.ServiceCardIcon id="contact-icon">{front1.icon}</cardST.ServiceCardIcon>
            <cardST.ServiceCardText id="contact-text">{back1.text}</cardST.ServiceCardText>
          </cardST.ServiceCardContent>
        </cardST.ContactCard>
      </a>
    );
  };

  return (
    <cst.SectionContent ref={contactRef} id="contactContainer">
      <cardST.SectionTitle style={{ paddingLeft: '7.5px', paddingRight: '7.5px' }}>REACH OUT TO US</cardST.SectionTitle>
      <cst.SubTitle className="grow">Let&apos;s build something together.</cst.SubTitle>
      <cst.ContactContainer>{renderCard1()}</cst.ContactContainer>
      <cst.ServicesButton onClick={handleServiceClick}>
        <IoIcons.IoIosArrowUp />
      </cst.ServicesButton>
    </cst.SectionContent>
  );
};

export default Contact;
