import React from 'react';
import { useNav } from '../../customHooks/useNav';
import * as st from './About.Styled';

const About = () => {
  const aboutRef = useNav('About');
  return (
    <st.AboutContainer ref={aboutRef} id="aboutContainer">
      <st.Title>WHO WE ARE</st.Title>
      <st.SubTitle className="grow">Ex-musicians</st.SubTitle>
      <st.SubTitle className="grow">Current Nerds</st.SubTitle>
      <st.SubTitle className="grow">Web3 Advocates</st.SubTitle>
      <st.SectionContainer>
        <st.CardContainer>
          <st.NathanContainer>
            <st.NathanImgHolder />
          </st.NathanContainer>
          <st.CardContent>
            <h1>Nathan Drake</h1>
            <h3>Co-Founder | Chief Product Officer</h3>
            <p>
              When his career as professional musician was derailed by the pandemic,
              Nathan spent a couple of years learning to code on his before discovering
              the Parsity bootcamp. Upon graduating, Nathan jumped into building in the
              Web3 space where he wants to help founders realize their visions.
            </p>
            <st.ContentOverlay />
          </st.CardContent>
        </st.CardContainer>
        <st.CardContainer id="doug">
          <st.DougContainer>
            <st.DougImgHolder />
          </st.DougContainer>
          <st.RevCardContent>
            <h1>Doug West</h1>
            <h3>Co-Founder | Chief Design Officer</h3>
            <p>
              After running restaurants for years in ATX, Doug and two of his chef friends
              were in the process of designing their own concept when Covid-19 abruptly
              upended their plans. With nothing to lose, Doug decided to join Nathan in
              learning to code at Parsity, and the rest is history.
            </p>
            <st.RevContentOverlay />
          </st.RevCardContent>
        </st.CardContainer>
      </st.SectionContainer>
    </st.AboutContainer>
  );
};
export default About;
