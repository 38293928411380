import React, { useState } from 'react';

export const NavContext = React.createContext();

// eslint-disable-next-line react/prop-types
const NavProvider = ({ children }) => {
  const [activeNavLinkId, setActiveNavLinkId] = useState('');

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const providerValue = {
    activeNavLinkId,
    setActiveNavLinkId,
  };

  return <NavContext.Provider value={providerValue}>{children}</NavContext.Provider>;
};

export default NavProvider;
