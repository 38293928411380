import React from 'react';
import * as MdIcons from 'react-icons/md';

export const front1 = {
  title: 'Project Inquiries',
  icon: <MdIcons.MdEmail />,
};

export const front2 = {
  title: 'Project Inquiries',
  icon: <MdIcons.MdEmail />,
};

export const front3 = {
  title: 'Front End Inquiries',
  icon: <MdIcons.MdEmail />,
};

export const back1 = {
  text: 'info@drakewestdev.com',
};

export const back2 = {
  text: 'nathan@drakewestdev.com',
};

export const back3 = {
  text: 'doug@drakewestdev.com',
};
