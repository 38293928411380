import styled from 'styled-components';
import bg from '../../Images/dwsite_bg.jpg';

export const HeroContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  background-image: radial-gradient(
      circle,
      rgba(45, 41, 38, 0) 0%,
      rgba(45, 41, 38, 1) 100%
    ),
    url(${bg});
  background-position: center;
  background-repeat: no-repeat;
  @media (max-width: 1000px) {
    padding-left: 80px;
    padding-right: 80px;
  }
`;

export const HeroContent = styled.div`
  height: 50%;
  width: 60%;
  max-width: 1300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media (max-width: 850px) {
    flex-direction: column;
    width: 100%;
    padding: 20px;
  }

  h1 {
    color: var(--light-text);
  }
`;

export const Logo = styled.h1`
  width: 70%;
  font-family: 'conthrax-sb';
  font-size: 80px;
  padding: 8px;
  letter-spacing: 18px;

  display: flex;
  flex-direction: column;
  color: var(--light-text);

  span {
    height: 100px;
    text-align: left;
  }

  h3 {
    margin-top: 2rem;
    font-size: 20px;
    letter-spacing: 9px;
  }

  @media (max-width: 1000px) {
    font-size: 60px;
    margin-top: 90px;
  }

  @media (min-width: 900px) {
    margin-right: 140px;
  }

  @media (min-width: 360px) {
    width: min-content;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  height: 40%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 850px) {
    margin-top: 2rem;
    padding: 20px;
  }
  @media (max-height: 1100px) {
    margin-top: 3.5rem;
    padding: 20px;
  }
`;

export const HeroText = styled.div`
  color: var(--light-text);
  text-align: center;
  min-width: 400px;
  @media (max-width: 600px) {
    max-width: 350px;
    margin-bottom: 60px;
  }

  h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 34px;
    font-weight: 600;
    @media (max-width: 500px) {
      font-size: 26px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  p {
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 500;
    @media (max-width: 400px) {
      font-size: 20px;
    }
  }
`;

export const ServicesButton = styled.button`
  height: 70px;
  width: 100%;
  max-width: 350px;
  margin: auto;
  margin-top: 20px;
  border: 3px solid var(--logo-red);
  background: transparent;
  border-radius: 20px;
  color: var(--logo-red);
  cursor: pointer;

  &:hover {
    background: var(--logo-red);
    color: var(--light-text);
  }

  @media (max-width: 500px) {
    padding: 20px;
    margin-top: -25px;
    margin-bottom: 35px;
    width: 75%;
  }
`;
