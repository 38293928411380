import styled from 'styled-components';

export const Nav = styled.nav`
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light-text);
  font-family: 'Poppins', sans-serif;
  z-index: 10;
  top: 0;
  padding: 1.2em 0;
  background: transparent;
  height: 6em;

  span {
    font-size: 16px;
    margin: 3em;
    padding-bottom: 0.3em;

    @media (min-width: 1615px) {
      font-size: 1.25rem;
    }

    @media (max-width: 800px) {
      margin: 1em;
    }

    :hover {
      color: var(--logo-red);
      cursor: pointer;
    }
  }
`;
