import React from 'react';
import './App.css';
import Services from './components/Services/Services';
import Nav from './components/Navigation/Nav';
import Hero from './components/Hero/Hero';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import AppContainer from './components/AppContainer';
import NavProvider from './context/NavContext';

function App() {
  return (
    <NavProvider>
      <AppContainer>
        <Nav />
        <Hero />
        <Services />
        <About />
        <Contact />
      </AppContainer>
    </NavProvider>
  );
}

export default App;
